<clr-datagrid
    [clrDgLoading]="isLoading"
    [ngClass]="[this.clrDatagridCssClass, this.height ? 'set-height' : this.heightOffset ? 'set-height-offset' : 'fill-parent-grid']"
    (clrDgRefresh)="gridStateChanged($event)"
    [sortablejs]="items"
    sortablejsContainer=".datagrid-table"
    [clrDgSelected]="selectitems"
    (clrDgSelectedChange)="selectionChange($event)"
    [sortablejsOptions]="{ draggable: '.datagrid-row', handle: '.drag-handle', disabled: isSortable !== true, onEnd: onSortEnd }"
>
    <clr-dg-placeholder>{{ emptyGridPlaceholder }}</clr-dg-placeholder>

    <ng-template #headerCells let-headers="headers" let-actions="actions">
        <clr-dg-column *ngFor="let column of headers" [clrDgSortBy]="column" (clrDgSortOrderChange)="resetToPageOne()" class="data-column" [ngClass]="column.className" [ngStyle]="{ 'width.px': column.width }">
            <ng-container *ngIf="isColumnHideable(column); else notHideable">
                <ng-template [clrDgHideableColumn]="{ hidden: column.hideable === GridColumnHideable.Hidden }" (clrDgHiddenChange)="onColumnHiddenChange(column, $event)">
                    <ng-container *ngIf="column.align === 'left' || !column.align">{{ column.displayName }}</ng-container>
                    <ng-container *ngIf="column.align === 'center'">
                        <span style="width: 100%; text-align: center">{{ column.displayName }}</span>
                    </ng-container>
                    <ng-container *ngIf="column.align === 'right'">
                        <span style="width: 100%; text-align: right">{{ column.displayName }}</span>
                    </ng-container>
                </ng-template>
            </ng-container>

            <ng-template #notHideable>
                <ng-container *ngIf="column.align === 'left' || !column.align">{{ column.displayName }}</ng-container>
                <ng-container *ngIf="column.align === 'center'">
                    <span style="width: 100%; text-align: center">{{ column.displayName }}</span>
                </ng-container>
                <ng-container *ngIf="column.align === 'right'">
                    <span style="width: 100%; text-align: right">{{ column.displayName }}</span>
                </ng-container>
            </ng-template>

            <clr-dg-filter *ngIf="column.queryFieldName && column.filter">
                <ng-template [fbrComponentRendererOutlet]="{ rendererSpec: column.filter }"></ng-template>
            </clr-dg-filter>
        </clr-dg-column>

        <clr-dg-column class="actions-header" [ngClass]="'buttons-' + this.getMaxFeaturedButtonsOnRow()" *ngIf="actions && shouldDisplayButtonsOnRow()">
            <span style="width: 100%; text-align: center">{{ 'common.actions' | translate }}</span>
        </clr-dg-column>
    </ng-template>

    <ng-container *ngTemplateOutlet="headerCells; context: { headers: columnsConfig | slice: 0:actionsColumnIndex, actions: true }"></ng-container>
    <ng-container *ngTemplateOutlet="headerCells; context: { headers: columnsConfig | slice: actionsColumnIndex }"></ng-container>

    <clr-dg-row
        *ngFor="let restItem of items; let i = index; let count = count; trackBy: trackBy"
        [ngForTrackBy]="trackBy"
        [ngClass]="this.clrDatarowCssClassGetter(restItem, i)"
        [clrDgItem]="restItem"
        [(clrDgSelected)]="restItem.selected"
    >
        <clr-dg-cell [fbrShowClippedText]="column.cliptextConfig" [ngClass]="column.align ? 'align-' + column.align : ''" *ngFor="let column of columnsConfig | slice: 0:actionsColumnIndex">
            <span *ngIf="isSortable" class="sort-handle">
                <clr-icon class="drag-handle" shape="drag-handle"></clr-icon>
            </span>
            <ng-container *ngTemplateOutlet="cell; context: { column: column, restItem: restItem }"></ng-container>
        </clr-dg-cell>

        <clr-dg-cell *ngIf="shouldDisplayButtonsOnRow()" class="actions-cell" [ngClass]="'buttons-' + this.getMaxFeaturedButtonsOnRow()">
            <ng-container *ngTemplateOutlet="actionsCell; context: { $implicit: [restItem], buttons: this.getFeaturedButtons([restItem]) }"></ng-container>
        </clr-dg-cell>

        <clr-dg-cell [fbrShowClippedText]="column.cliptextConfig" [ngClass]="column.align ? 'align-' + column.align : ''" *ngFor="let column of columnsConfig | slice: actionsColumnIndex">
            <ng-container *ngTemplateOutlet="cell; context: { column: column, restItem: restItem }"></ng-container>
        </clr-dg-cell>

        <ng-container ngProjectAs="clr-dg-row-detail" *ngIf="detailComponent !== undefined">
            <clr-dg-row-detail *clrIfExpanded="isRowExpanded">
                <ng-template [fbrComponentRendererOutlet]="{ rendererSpec: getDetailRowRenderSpec(restItem, i, count) }"></ng-template>
            </clr-dg-row-detail>
        </ng-container>
    </clr-dg-row>

    <ng-container ngProjectAs="clr-dg-detail" *ngIf="detailPane">
        <ng-template [(clrIfDetail)]="detailItem" let-detail>
            <clr-dg-detail>
                <ng-template [fbrComponentRendererOutlet]="{ rendererSpec: getDetailPaneRenderSpec(detail) }"></ng-template>
            </clr-dg-detail>
        </ng-template>
    </ng-container>

    <clr-dg-footer>
        <clr-dg-column-toggle>
            <clr-dg-column-toggle-title>{{ 'common.show-columns' | translate }}</clr-dg-column-toggle-title>
            <clr-dg-column-toggle-button>{{ 'common.select-all' | translate }}</clr-dg-column-toggle-button>
        </clr-dg-column-toggle>
        <clr-dg-pagination #paginationInfo [clrDgTotalItems]="totalItems" [clrDgPageSize]="this.pageSize" [clrDgPageInputDisabled]="!this.pagination.shouldShowPageNumberInput">
            <div>{{ 'common.pagination' | translate: { first: paginationInfo.firstItem + 1, last: paginationInfo.lastItem + 1, total: paginationInfo.totalItems } }}</div>
            <clr-dg-page-size [clrPageSizeOptions]="this.pageSizeOptions" *ngIf="this.pagination.shouldShowPageSizeSelector">{{ paginationDropdownText }}</clr-dg-page-size>
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>

<ng-template #actionsCell let-selection let-buttons="buttons">
    <div class="btn-group btn-outline btn-sm">
        <div *ngFor="let button of buttons" style="position: relative;">
            <button  class="btn action-btn" (click)="runButtonHandler(button, selection)" [disabled]="isButtonDisabled(button)" [id]="buttonGroupedId(button)"  [ngClass]="{'icon-btn': isShowIconOnly()}">
                <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm" *ngIf="shouldShowIcon()" style="position: inherit;">
                    <clr-icon
                        size="1em"
                        [attr.shape]="button.icon"
                        class="action-icon"
                        [ngClass]="{
                            'icon-red': button.iconColour === 'RED',
                            'icon-green': button.iconColour === 'GREEN',
                            enable: button.iconColour === 'SENABLE',
                            disable: button.iconColour === 'SDISABLE',
                            'is-solid': button.class === 'enable' || button.class === 'disable'
                        }"
                    ></clr-icon>
                    <div class="tooltip-right tooltip-md tooltip-content">
                        <span class="tooltip-content" *ngIf="shouldShowTooltip()">{{ button.label }}</span>
                    </div>            
                </a>        
                <ng-container *ngIf="shouldShowText()">{{ button.label }}</ng-container>
            </button>
    </div>
    </div>
</ng-template>

<ng-template #cell let-column="column" let-restItem="restItem">
    <a *ngIf="column.linkTo && !column.isMatricsBillboard" class="link-to" [routerLink]="['./', restItem | nestedProperty: column.linkTo]">
        <ng-container *ngTemplateOutlet="cellContents; context: { column: column, restItem: restItem }"></ng-container>
    </a>
    <a *ngIf="column.linkTo && column.isMatricsBillboard" class="link-to" [routerLink]="['/campaign-cast/reports', restItem | nestedProperty: column.linkTo]">
        <ng-container *ngTemplateOutlet="cellContents; context: { column: column, restItem: restItem }"></ng-container>
    </a>
    <a *ngIf="column.params" class="params" [routerLink]="['./']" [queryParams]="{ smartCampaignId: restItem.id, wizard: true }">
        <ng-container *ngTemplateOutlet="cellContents; context: { column: column, restItem: restItem }"></ng-container>
    </a>
    <a *ngIf="column.handler" class="link-to" (click)="column.handler(restItem)">
        <ng-container *ngTemplateOutlet="cellContents; context: { column: column, restItem: restItem }"></ng-container>
    </a>
    <ng-container *ngIf="!column.linkTo && !column.handler && !column.params">
        <ng-container *ngTemplateOutlet="cellContents; context: { column: column, restItem: restItem }"></ng-container>
    </ng-container>
</ng-template>

<ng-template #cellContents let-column="column" let-restItem="restItem">
    <ng-container *ngIf="column.fieldName">{{ restItem | nestedProperty: column.fieldName }}</ng-container>
    <ng-container *ngIf="column.fieldRenderer"><span [innerHTML]="restItem | functionRenderer: column.fieldRenderer"></span></ng-container>
    <ng-template *ngIf="column.fieldColumnRendererSpec" [fbrComponentRendererOutlet]="{ rendererSpec: column.fieldColumnRendererSpec, context: restItem }"></ng-template>
</ng-template>
