import { HttpClient } from '@angular/common/http'
import { Environment } from '@fabrik/common'
import { Observable } from 'rxjs'

import { AudienceStats, ChatStats } from '../models/Metrics'
import { TimeUtils } from '../utils/time-utils'

export class TenantDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    getAudienceStats(tenantId: string, startDate?: Date, endDate?: Date): Observable<AudienceStats[]> {
        let dateRangeParams = ''

        if (startDate && endDate) {
            dateRangeParams += `?startDate=${TimeUtils.recreateDateAsUTC(startDate).toISOString()}&endDate=${TimeUtils.recreateDateAsUTC(endDate).toISOString()}`
        }
        return this.http.get<AudienceStats[]>(`${this.environment.metricsApiHost}/tenantstats/${tenantId}/profiles${dateRangeParams}`)
    }

    getChatStats(tenantId: string, startDate: Date, endDate: Date): Observable<ChatStats[]> {
        return this.http.get<ChatStats[]>(`${this.environment.metricsApiHost}/tenantstats/${tenantId}/chats?startDate=${TimeUtils.recreateDateAsUTC(startDate).toISOString()}&endDate=${TimeUtils.recreateDateAsUTC(endDate).toISOString()}`)
    }
}
