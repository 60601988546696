import { Observable } from 'rxjs'

import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http'
import { Environment, PaginatedList, utils } from '@fabrik/common'

import {
    AppsContentCategory,
    CompleteContentFeed,
    ContentCategory,
    ContentFeedCategory,
    ContentUploadFile,
    ItemContent,
    Item,
    CompleteUpdateContentFeed,
    Tabs,
    Tab,
    Config,
    Configuration,
    SourceTypes,
    TargetTenant,
    TargetTenantCategory,
    TargetTenantChannels,
    ImportItem
} from '../models/ContentFeed'

export class ContentFeedDataService {
    constructor(private environment: Environment, private http: HttpClient) {}

    /**
     * List categories on mixed content feed
     *
     * @param tenantId id of the current tenant
     * @param sortField field to sort
     * @param sortDirection ascending or descending
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */

    list(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<ContentFeedCategory>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<ContentFeedCategory>>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedCategories`, { params })
    }

    /**
     * Query list categories on mixed content feed with no params no auth API used by apps
     *
     */

    listCategory(tenantId: string): Observable<AppsContentCategory> {
        return this.http.get<AppsContentCategory>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedCategories?sortField=CreatedAt&sortDirection=Descending&limit=1000`)
    }

    /**
     * Retrieve content category by id.
     *
     * @param tenantId id of the current tenant
     * @param mixedContentCategoryId id of the category to retrieve
     */
    byId(tenantId: string, categoryId: string): Observable<ContentCategory> {
        return this.http.get<ContentCategory>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedCategories/${categoryId}`)
    }

    /**
     * Update a mixed content category item
     *
     * @param tenantId id of the current tenant
     * @param data for a existing mixed content category object
     */
    update(tenantId: string, categoryId: string, payload: ContentCategory): Observable<ContentCategory> {
        return this.http.put<ContentCategory>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedCategories/${categoryId}`, payload)
    }

    /**
     * Delete category on mixed content feed
     *
     * @param tenantId id of the current tenant
     * @param categoryId id of the category to delete
     */
    delete(tenantId: string, categoryId: string): Observable<ContentFeedCategory> {
        return this.http.delete<ContentFeedCategory>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedCategories/${categoryId}`)
    }

    /**
     * Create a category
     *
     * @param tenantId id of the current tenant
     * @param data category object
     */
    create(tenantId: string, data: ContentCategory): Observable<ContentCategory> {
        return this.http.post<ContentCategory>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedCategories`, data)
    }

    /**
     * Upload videos and audio mixed content files( With progress events )
     * @param tenantId id of the current tenant
     * @param file file to upload
     */
    upload(tenantId: string, file: any): Observable<HttpEvent<ContentUploadFile>> {
        return this.http.post<ContentUploadFile>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedAttachments/upload-url`, file, {
            reportProgress: true,
            observe: 'events'
        })
    }

    /**
     * Upload other mixed content files( With progress events )
     * @param tenantId id of the current tenant
     * @param file file to upload
     */
    uploadFile(tenantId: string, file: FormData): Observable<HttpEvent<ContentUploadFile>> {
        return this.http.post<ContentUploadFile>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedAttachments`, file, {
            reportProgress: true,
            observe: 'events'
        })
    }

    /**
     * Upload all mixed content files
     * @param tenantId id of the current tenant
     * @param file file to upload
     */
    simpleUploadFile(tenantId: string, file: FormData): Observable<ContentUploadFile> {
        return this.http.post<ContentUploadFile>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedAttachments`, file)
    }

    /**
     * List content on items
     *
     * @param tenantId id of the current tenant
     * @param sortField field to sort
     * @param sortDirection ascending or descending
     * @param page paginated page index
     * @param limit max number of records to return
     * @param query search query
     */

    listItemContent(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; title?: any; status?: any } = {}): Observable<PaginatedList<ItemContent>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })

        return this.http.get<PaginatedList<ItemContent>>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedContents`, { params })
    }

    /**
     * Delete item on mixed content feed
     *
     * @param tenantId id of the current tenant
     * @param itemId id of the item to delete
     */
    deleteItem(tenantId: string, itemId: string): Observable<Item> {
        return this.http.delete<Item>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedItems/${itemId}`)
    }

    /**
     * Create complete content feed
     *
     * @param tenantId id of the current tenant
     * @param data category object
     */
    createCompleteContent(tenantId: string, data: CompleteContentFeed): Observable<CompleteContentFeed> {
        return this.http.post<CompleteContentFeed>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedContents`, data)
    }

    /**
     * Retrieve Itemcontent by id.
     *
     * @param tenantId id of the current tenant
     * @param mixedContentItemId id of the category to retrieve
     */
    itemContentById(tenantId: string, ItemId: string): Observable<CompleteUpdateContentFeed> {
        return this.http.get<CompleteUpdateContentFeed>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedContents/item/${ItemId}`)
    }

    /**
     * Update a mixed content item content
     *
     * @param tenantId id of the current tenant
     * @param data for a existing mixed content category object
     */
    itemContentupdate(tenantId: string, payload: CompleteUpdateContentFeed): Observable<CompleteUpdateContentFeed> {
        return this.http.put<CompleteUpdateContentFeed>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedContents`, payload)
    }

    /**
     * Get all tabs
     *
     * @param tenantId id of the current tenant
     */
    getTabs(tenantId: string): Observable<Tabs> {
        return this.http.get<Tabs>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeeds`)
    }

    /**
     * Create a tab
     *
     * @param tenantId id of the current tenant
     * @param data tab object
     */
    createTab(tenantId: string, data: Tab): Observable<Tab> {
        return this.http.post<Tab>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeeds`, data)
    }

    /**
     * Update a tab item
     *
     * @param tenantId id of the current tenant
     * @param data for a existing tab object
     */
    updateTab(tenantId: string, tabId: string, payload: Tab): Observable<Tab> {
        return this.http.put<Tab>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeeds/${tabId}`, payload)
    }

    /**
     * Retrieve tab by id.
     *
     * @param tenantId id of the current tenant
     * @param tabId id of the tab to retrieve
     */
    byIdTab(tenantId: string, tabId: string): Observable<Tab> {
        return this.http.get<Tab>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeeds/${tabId}`)
    }

    /**
     * Delete tab
     *
     * @param tenantId id of the current tenant
     * @param tabId id of the category to delete
     */
    deleteTab(tenantId: string, tabId: string): Observable<Tab> {
        return this.http.delete<Tab>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeeds/${tabId}`)
    }

    /**
     * Delete tab
     *
     * @param tenantId id of the current tenant
     * @param payload array with ordered categories
     */
    saveOrder(tenantId: string, payload: any): Observable<ContentCategory> {
        return this.http.put<ContentCategory>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedCategories/order`, payload)
    }

    /**
     * Publish and unPublish content
     *
     * @param tenantId id of the current tenant
     * @param itemId id of the item
     * @param toPublish boolean
     */
    toPublish(tenantId: string, itemId: string, toPublish: any): Observable<any> {
        return this.http.put<any>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedItems/${itemId}/status`, toPublish)
    }

    /**
     * Bulk Actions
     *
     * @param tenantId id of the current tenant
     * @param actionState id of the item
     * @param toPublish boolean
     */
    bulkAction(tenantId: string, actionState: number, itemIds: any): Observable<any> {
        return this.http.put<any>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedItems/bulkstatus?action=${actionState}`, itemIds)
    }

    /**
     * Query list importer configurations linked to categories
     *
     */

    listConfig(tenantId: string, args: { sortField?: string; sortDirection?: string; page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<Config>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })
        return this.http.get<PaginatedList<Config>>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedImports/configs`, { params })
    }

    /**
     * Delete configuration
     *
     * @param tenantId id of the current tenant
     * @param configurationId id of the configuration to delete
     */
    deleteConfig(tenantId: string, configurationId: string): Observable<Config> {
        return this.http.delete<Config>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedImports/configs/${configurationId}`)
    }

    /**
     * Create configuration
     *
     * @param tenantId id of the current tenant
     * @param data configuration object
     */
    createConfig(tenantId: string, data: Configuration): Observable<Configuration> {
        return this.http.post<Configuration>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedImports/configs`, data)
    }

    /**
     * Update configuration
     *
     * @param tenantId id of the current tenant
     * @param data for an existing configuration object
     */
    updateConfig(tenantId: string, configId: string, payload: Configuration): Observable<Configuration> {
        return this.http.put<Configuration>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedImports/configs/${configId}`, payload)
    }

    /**
     * Retrieve configuration by id.
     *
     * @param tenantId id of the current tenant
     * @param configId id of the tab to retrieve
     */
    byIdConfig(tenantId: string, configId: string): Observable<Configuration> {
        return this.http.get<Configuration>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedImports/configs/${configId}`)
    }

    /**
     * Retrieve configuration source types.
     *
     * @param tenantId id of the current tenant
     */
    getSourceTypes(tenantId: string): Observable<SourceTypes> {
        return this.http.get<SourceTypes>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedImports/configs/sourceTypes`)
    }

    /**
     * Retrieve tenants.
     *
     * @param searchString string to be seached
     * @param tenantId id of the current tenant
     */
    getTargetTenant(tenantId: string, searchString: string): Observable<TargetTenant> {
        return this.http.get<TargetTenant>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedImports/configs/tenants?limit=200&query=` + searchString)
    }

    /**
     * Retrieve echocast categories of selected tenant.
     *
     * @param tenantId id of the current tenant
     * @param targetTenantId id of selected tenant
     */
    getTenantCategories(tenantId: string, targetTenantId: string): Observable<TargetTenantCategory> {
        return this.http.get<TargetTenantCategory>(`${this.environment.echocastHost}/api/${tenantId}/category/cross-tenant/${targetTenantId}`)
    }

    /**
     * Retrieve Rss Feed categories of selected tenant.
     *
     * @param tenantId id of the current tenant
     */
    getTenantRssCategories(tenantId: string): Observable<TargetTenantCategory> {
        return this.http.get<TargetTenantCategory>(`${this.environment.appApiHost}/api/${tenantId}/admin/news/newsImportDescriptor?limit=200&sortField=Order&sortDirection=Ascending&query=&isActive=true`)
    }

    /**
     * Retrieve Youtube channels of selected tenant.
     *
     * @param tenantId id of the current tenant
     * @param targetTenantId id of selected tenant
     */
    getTenantChannels(tenantId: string, targetTenantId: string): Observable<TargetTenantChannels> {
        return this.http.get<TargetTenantChannels>(`${this.environment.appApiHost}/api/${tenantId}/admin/YoutubeChannels/cross-tenant/${targetTenantId}`)
    }

    /**
     * Retrieve mixed categories of selected tenant.
     *
     * @param tenantId id of the current tenant
     * @param targetTenantId id of selected tenant
     */
    getTenantCategoriesMixed(tenantId: string, targetTenantId: string): Observable<TargetTenantCategory> {
        return this.http.get<TargetTenantCategory>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedCategories/cross-tenant/${targetTenantId}?limit=500`)
    }

    /**
     * Retrieve external content items from sources(Echocast, RSS feed and YouTube videos).
     *
     * @param tenantId id of the current tenant
     * @param importSourceType source number
     */
    listExternalSourceItem(tenantId: string, importSourceType: number, args: { page?: number; limit?: number; query?: any } = {}): Observable<PaginatedList<ImportItem>> {
        let params = new HttpParams()

        Object.keys(args).forEach(key => {
            switch (key) {
                case 'page':
                    if (utils.notNullOrUndefined(args.page)) {
                        params = params.set('page', (args.page - 1).toString())
                    }
                    break

                default:
                    if (utils.notNullOrUndefined(args[key])) {
                        params = params.set(key, args[key])
                    }
            }
        })
        return this.http.get<PaginatedList<ImportItem>>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedImports/source/${importSourceType}`, { params })
    }

    /**
     * Post item to mixed content items
     *
     * @param tenantId id of the current tenant
     * @param data configuration object
     */
    importExternalItem(tenantId: string, data: any): Observable<ImportItem> {
        return this.http.post<ImportItem>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedImports/externalContent`, data)
    }

    /**
     * Reproces item
     *
     * @param tenantId id of the current tenant
     * @param itemId content item id
     */
    reprocess(tenantId: string, itemId: string): Observable<ContentCategory> {
        return this.http.post<ContentCategory>(`${this.environment.appApiHost}/api/${tenantId}/admin/mixedFeedContents/reprocess?MixedFeedItemId=${itemId}`, {})
    }
}
