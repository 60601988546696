export enum RecordingStatus {
    recording = 0,
    incomplete = 1,
    queued = 2,
    processing = 3,
    complete = 4,
    published = 5,
    error = 6,
    paused = 8
}

export enum LocalRecordingStates {
    recording = 'recording',
    stopped = 'stopped',
    paused = 'paused'
}

export enum FilterTimes {
    All,
    Today,
    Yesterday,
    Last7days,
    Last30days,
    Lastmonth,
    CustomRange
}
